import React from 'react'
import { Link } from 'react-router-dom'

import Swiper from 'react-id-swiper';
import 'swiper/swiper-bundle.css';
import toastr from 'toastr'

import SwiperCore, { Lazy, Navigation, Autoplay, Pagination } from 'swiper'


import Bg1 from '../../img/bg1.jpg'
import Bg2 from '../../img/bg2_.JPG'
import Bg3 from '../../img/bg2.jpg'
import Bg4 from '../../img/bg4.jpg'

import GardenImg from '../../img/garden-img.jpg'

import Worker1 from '../../img/worker-img1.jpg'
import Worker2 from '../../img/worker-img2_.jpg'
import Worker3 from '../../img/worker-img2.jpg'
import Worker4 from '../../img/worker-img3_.jpg'
import Worker5 from '../../img/worker-img4.jpg'
import Worker6 from '../../img/worker-img3.jpg'

import Process1 from '../../img/process-img1.jpg'
import Process2 from '../../img/process-img2.jpg'
import Process3 from '../../img/process-img3.jpg'

// import ArrowRight from '../../img/arrow-right.svg'
// import ArrowLeft from '../../img/arrow-left.svg'

import Star from '../../img/star-img.svg'
import ReviewImg1 from '../../img/review-img1.jpg'
import ReviewImg2 from '../../img/review-img2.png'
import ReviewImg3 from '../../img/review-img3.png'
import ReviewImg4 from '../../img/review-img4.png'

// import PhoneIcon from '../../img/phone-icon.svg'
// import MarkerIcon from '../../img/marker-icon.svg'
// import CloseIcon from '../../img/close-icon.svg'

import About1 from '../../img/about4.JPG'
import About2 from '../../img/about5.JPG'
import About3 from '../../img/about5_.JPG'
import About4 from '../../img/about5__.jpg'
import About5 from '../../img/about6.JPG'
import About6 from '../../img/about7.JPG'
import About7 from '../../img/about7_.jpg'
import About8 from '../../img/about8.JPG'
import About9 from '../../img/about9.JPG'
import About10 from '../../img/about10.jpg'
import About11 from '../../img/about13.JPG'
import About12 from '../../img/about14.JPG'
import About13 from '../../img/about15.JPG'
import About14 from '../../img/about16.JPG'
import About15 from '../../img/about17.JPG'
import About16 from '../../img/about18.JPG'
import About17 from '../../img/about19.JPG'
import About18 from '../../img/about21.JPG'
import About19 from '../../img/about22.JPG'
import About20 from '../../img/about23.JPG'
import About21 from '../../img/about24.JPG'

import { Carousel } from 'rsuite'

SwiperCore.use([Lazy, Navigation, Autoplay, Pagination ])


const About = (props) => {
	const name = 'ololo'
	const phone = '321'
	const cloudfunction = 'https://us-central1-buzunko-stas.cloudfunctions.net/newOrder'
  const aboutImages = [
    About1, About2, About3,
    About4, About5, About6, About7, About8,
    About9,
    About10, About11, About12, About13, About14,
    About15, About16, About17, About18, About19,
    About20, About21
  ]
  const params = {
    // slidesPerView: 3,
    // spaceBetween: 30,
    grabCursor: true,
    loop: true,
    centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    autoplay: {
      delay: 2500,
      disableOnInteraction: false
    },
    breakpoints: {
      319: {
        slidesPerView: 1.75,
        spaceBetween: 10,
      },
      760: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      769: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    }
  }
  const paramsReview = {
    grabCursor: true,
    // loop: true,
    // centeredSlides: true,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    // },
    // autoplay: {
    //   delay: 4000,
    //   disableOnInteraction: false
    // },
    breakpoints: {
      319: {
        slidesPerView: 1,
        spaceBetween: 0,
      },
      767: {
        slidesPerView: 2,
        spaceBetween: 30,
        centeredSlides: true,
      },
      769: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    }
  }
  const paramsAbout = {
    grabCursor: true,
    loop: true,
    spaceBetween: 10,
    // centeredSlides: true,
    slidesPerView: 1,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    // },
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    },
  }

  const addReview = () => {
    toastr.warning('We will add this opportunity soon', '', {  
      "positionClass": "toast-top-center",
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
    })
  }
	return (
		<div>
      <div className="container container-header" id='header'>
        <div className="row pb60">
          <div className="header">
            <h4>
              <span className='orange-text'>
                Pure <br/>raw honey
              </span>
              <br/>
              from our family farm
            </h4>
            <p>
              <span className="bold-text">Free delivery</span> can be<br/>
              arranged in <span className="bold-text">Rimbey area</span>
            </p>
            <br/><br/>
            <Link to='/products'>
              <button className="common-button_orange">
                SHOP NOW
              </button>
            </Link>
          </div>

          <Carousel
            autoplay
            className="header-carousel"
          >
            <img src={Bg1} alt="" />
            <img src={Bg2} alt="" />
            <img src={Bg4} alt="" />
            <img src={Bg3} alt="" />
          </Carousel>
        </div>
      </div>

      <div className="container container-about" id='about'>
        <div>
          <h3 className="title">
            About us
          </h3>
          <p className="text">
            Andrii Horobets, owner of Horobets Bee Ranch, is an 15 yr commercial beekeeper that specializes 
            in queen breeding, honey and pollen production, pollination services and hive management 
            consultation. His family farm is a second  generation farm started by Andrii's father in Ukraine 
            back in 1988. After moving to Canada in 2017 Andrii, along with Alina Horobets, and their two 
            children make up a family-owned and operated apiary located in Rimbey, Alberta.
          </p>
        </div>
        <div className="row about-slider">
          <Swiper
            {...paramsAbout}
          >
            {aboutImages.map((aboutImg, i) => (
              <img
                className="rounded-img"
                src={aboutImg}
                style={{maxWidth: '100%', maxHeight: '620px'}}
                alt=""
                key={i}
              />
            ))}
          </Swiper>
        </div>
      </div>

      <div className="container container-team" id='team' style={{overflow: 'unset'}}>
        <h3 className="title">
          Our Team
        </h3>
        <div className="row pb40" style={{display: 'block', margin: '0 auto',overflow: 'unset'}}>
          <div className='kek' style={{maxWidth: 900,margin: 'auto'}}>
            <Swiper
              {...params}
              className='worker-slider'
            >
              <div className='worker-slider_item'>
                <img src={Worker1} alt=""/>
                <p>Andrii Horobets</p>
                <p>
                  Co-owner,<br/> beekeeper
                </p>
              </div>
              <div className='worker-slider_item'>
                <img src={Worker2} alt=""/>
                <p className='worker-slider_item-extraText'>
                  Kerry and Michelle Stevens
                </p>
                <p>
                  Co-owners,<br/> beekeepers
                </p>
              </div>
              <div className='worker-slider_item'>
                <img src={Worker3} alt=""/>
                <p>
                  Alina Horobets
                </p>
                <p>
                  Co-owner,<br/> beekeeper
                </p>
              </div>
              <div className='worker-slider_item'>
                <img src={Worker4} alt=""/>
                <p>Stevens</p>
                <p>
                  Chief tasters
                  <br/>
                  <br/>
                </p>
              </div>
              <div className='worker-slider_item'>
                <img src={Worker5} alt=""/>
                <p>
                  Vlada Horobets
                </p>
                <p>
                  Chief taster
                  <br/>
                  <br/>
                </p>
              </div>
              <div className='worker-slider_item'>
                <img src={Worker6} alt=""/>
                <p>Yuliya Horobets</p>
                <p>
                  IT director
                  <br/>
                  <br/>
                </p>
              </div>
            </Swiper>
          </div>
        </div>
      </div>

      <div className="container container-stats" id='stats'>
        <h3 className="title">
          Apiary <br/>
          in numbers 
        </h3>
        <div className="row pb20 lel" >
          <div className="stats-row">
            <p className="stats-row_text">
              1000+
            </p>
            <p>lbs of honey sold</p>
          </div>
          <div className="stats-row">
            <p className="stats-row_text">50</p>
            <p>bee colonies</p>
          </div>
          <div className="stats-row">
            <p className="stats-row_text">
              100+
            </p>
            <p>customers</p>
          </div>
        </div>
      </div>

      <div className="container container-process" id="process">
        <h3 className="title">
          Extracting process
        </h3>
        <div className="row pb20">
          <div className="process-block">
            <div className="process-block_number">
              1
            </div>
            <div className="process-block_info">
              <h4>Selecting frames</h4>
              <p className="text">
                We put on bee suits and begin to inspect the hives, we select only the cupped frames with honey.
                If the bees have cupped the frame, that means the honey is ready for extraction.
              </p>
              <img
                src={Process1}
                width="250"
                height="150"
                alt=""
                className="rounded-img"
              />
            </div>
          </div>
          <div className="process-block">
            <div className="process-block_number">
              2
            </div>
            <div className="process-block_info">
              <h4>Uncupping honeycomb</h4>
              <p className="text">
                With a uncupping knife we cut off wax cupping from the frames with honeycombs.
              </p>
              <img
                src={Process2}
                width="250"
                height="150"
                alt=""
                className="rounded-img"
              />
            </div>
          </div>
          <div className="process-block">
            <div className="process-block_number">
              3
            </div>
            <div className="process-block_info">
              <h4>Extraction</h4>
              <p className="text">
                Place the open frames in the honey extractor and extract honey.
                During extraction we enjoy the incredible aroma of honey that fills all room.
              </p>
              <img
                src={Process3}
                width="250"
                height="150"
                alt=""
                className="rounded-img"
              />
            </div>
          </div>
        </div>
      </div>

      <div className="container container-review pb60" id="review">
        <h3 className="title">
          Reviews
        </h3>
        <div>
          <div className='review-slider'>
            <div className="review-card">
            <div className="review-card_person">
              <div>
                <img
                  src={ReviewImg1}
                  width="100"
                  height="100"
                  alt=""
                />
              </div>
              <div className="review-card_person-info">
                <h4>Andrii</h4>
                <span className="text">Сo-owner, beekeeper</span>
                <div>
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <p className="text">
              Here can be your review ;-)
            </p>
            <button className="common-button_orange" style={{marginTop: '2rem'}} onClick={(e)=> {
                  e.preventDefault()
                  addReview()
            }}>
              Add review
            </button>
          </div>
          
        <Swiper
          {...paramsReview}
          // containerClass='
        >
          {/*<div className="review-card">
            <div className="review-card_person">
              <div>
                <img
                  src={ReviewImg1}
                  width="100"
                  height="100"
                  layout='flex-item'
                  alt=""
                />
              </div>
              <div className="review-card_person-info">
                <h4>Jack</h4>
                <span className="text">Calgary</span>
                <div>
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <p className="text">
              We buy honey every year and only from this seller.
              The quality, as always, is on top. Children from the plate to tear. The whole family is happy :)
            </p>
          </div>

          <div className="review-card">
            <div className="review-card_person">
              <div>
                <img
                  src={ReviewImg2}
                  width="100"
                  height="100"
                  alt=""
                />
              </div>
              <div className="review-card_person-info">
                <h4>Silvia</h4>
                <span className="text">Red Deer</span>
                <div>
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <p className="text">
              I love honey since childhood, and therefore I buy it often.
              I changed a lot of sellers, but I don't know any problems with these guys for several years.
              Everything is tasty and relatively inexpensive, I advise everyone!
            </p>
          </div>

          <div className="review-card" >
            <div className="review-card_person">
              <div>
                <img
                  src={ReviewImg3}
                  width="100"
                  height="100"
                  alt=""
                />
              </div>
              <div className="review-card_person-info">
                <h4>Helen</h4>
                <span className="text">Edmonton</span>
                <div>
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <p className="text">
              Well, just a taste of this honey from my childhood.
              I tried this only at my grandmother in the village. Thank you guys for your prompt response!)
            </p>
          </div>

          <div className="review-card">
            <div className="review-card_person">
              <div>
                <img
                  src={ReviewImg4}
                  width="100"
                  height="100"
                  alt=""
                />
              </div>
              <div className="review-card_person-info">
                <h4>Frank</h4>
                <span className="text">Blackfalds</span>
                <div>
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                  <img
                    src={Star}
                    width="14"
                    height="14"
                    alt=""
                  />
                </div>
              </div>
            </div>
            <p className="text">
              I'm not a big fan of sweets, but sometimes my wife really wants to pamper herself with a natural product.
              Here honey of excellent quality comes to the rescue))
            </p>
          </div>*/}
        </Swiper>

        </div>
    


          {/* <Carousel autoplay className="custom-slider" style={{backgroundColor:'#ffece1'}}>
            <div style={{backgroundColor:'#ffece1'}}>
              
            </div>
            <div style={{backgroundColor:'#ffece1'}}>
              
            </div>
            <div style={{backgroundColor:'#ffece1'}}>
             
            </div>
            <div style={{backgroundColor:'#ffece1'}}>
              
            </div>
          </Carousel> */}
        </div>
      </div>
		</div>
	)
}

export default About