import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { Navbar, Drawer, Affix } from 'rsuite'
import Logo from '../img/logo.png'
import menu from '../img/menu.svg'


const NavBar = () => {

  // toggl

  // constructor(props) {
  //   super(props);
  //   this.state = {
  //     show: false
  //   };
  //   this.close = this.close.bind(this);
  //   this.toggleDrawer = this.toggleDrawer.bind(this);
  // }
  // close() {
  //   this.setState({
  //     show: false
  //   });
  // }
  // toggleDrawer() {
  //   this.setState({ show: true });
  // }

  const [openMenu,setOpenMenu] = useState(false)

  return (
    <Affix>
      <Navbar className='navbar'>
        <div className='navbar-desktop'>
          <div>
            <a href="/" className="logo">
              <img src={Logo} alt="" />
              <p>
                HOROBETS <br/>BEE RANCH
              </p>
            </a>
            <Link to="/">
              About us
            </Link>
            <Link to="/products">
              Products
            </Link>
            <Link to="/rental">
              Rental
            </Link>
            <Link to="/pollination">
              Pollination
            </Link>
            <Link to="/contacts">
              Contacts
            </Link>
          </div>
          <a className="phone" href="tel:+14033964494">
            <div className="phone-icon-wrap">
              <div className="phone-icon" />
            </div>
            +14033964494
          </a>
        </div>
        <div className='navbar-mobile'>
          <a href="/" className="logo">
            <img src={Logo} alt="" />
          </a>
          
          <a className="phone" href="tel:+14033964494">
            +14033964494
          </a>

          <button onClick={()=>setOpenMenu(true)}>
            <img src={menu} alt=""/>
          </button>

          <Drawer
            show={openMenu}
            size='xs'
            placement='right'
            onHide={()=>setOpenMenu(false)}
            className='mobile-menu'
          >
            <Drawer.Header>
              <a href="/" className="logo" onClick={()=>setOpenMenu(false)}>
                <img src={Logo} alt=""  height={75} />
              </a>
            </Drawer.Header>
            <Drawer.Body>
              <Link to="/" onClick={()=>setOpenMenu(false)}>
                About us
              </Link>
              <Link to="/products" onClick={()=>setOpenMenu(false)}>
                Products
              </Link>
              <Link to="/rental" onClick={()=>setOpenMenu(false)}>
                Rental
              </Link>
              <Link to="/pollination" onClick={()=>setOpenMenu(false)}>
                Pollination
              </Link>
              <Link to="/contacts" onClick={()=>setOpenMenu(false)}>
                Contacts
              </Link>
            </Drawer.Body>
          </Drawer>

        </div>
      </Navbar>
    </Affix>
  );
};

export default NavBar