import React from "react"
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"

// import mapIcon from '../img/mapIcon.svg'
// import Logo from '../img/logo.svg'
import mapIcon from '../img/honeycombMap.svg'
import phone from '../img/phone.svg'
import mailIcon from '../img/mailIcon.svg'
import adressPointerBlue from '../img/adressPointerOrange.svg'

import InstagramIcon from '../img/InstagramOrange.js'
import FaceBookIcon from '../img/FacebookOrange.js'
import YoutubeIcon from '../img/YouTubeOrange.js'
import ViberIcon from '../img/ViberOrange.js'

const locationProps = {
  center: {lat: 52.639349, lng: -114.244892},
  zoom: 10
}
const icon = { url: mapIcon, scaledSize: { width: 35, height: 35 } };

const ContactsMain = () => {
  return (
    <div className="container container-contacts" id="contacts" style={{paddingLeft: 0, paddingRight: 0,overflow:'hidden'}}>
        <h3 className="title">
          Location
        </h3>

        <div className='contact-map_card_mobile'>
          <h2>Contacts</h2>
          <div className='contact-map_card-row'>
            <p>
              <img src={phone} alt=""/>
              Phone
            </p>
            <a href="tel:+14033964494">
              <span>+1403-396-4494</span>
            </a>
          </div>
          <div className='contact-map_card-row'>
            <p>
              <img src={adressPointerBlue} alt=""/>
              Adress
            </p>
            <a href="https://goo.gl/maps/YYj81ouejxw6yT2G8" target="__blank">
              <span>Rimbey, Alberta</span>
            </a>
          </div>
          <div className='contact-map_card-row'>
            <p>
              <img src={mailIcon} alt=""/>
              Email
            </p>
            <a href="mailto:horobets.bee.ranch@gmail.com">
              <span>horobets.bee.ranch@gmail.com</span>
            </a>
          </div>
          <div className='contact-socials'>
            <a href="">
              <InstagramIcon />
            </a>
            <a href="https://www.facebook.com/HorobetsBeeRanch" target="__blank">
              <FaceBookIcon />
            </a>
            <a href="tel:+14033964494">
              <ViberIcon />
            </a>
            <a href="">
              <YoutubeIcon />
            </a>
          </div>
        </div>

        <div className='contact-map_wrap'>
          <MyMapComponent
            isMarkerShown
            googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDWEP2VV5iCm287wPW6jZz4gBX_6TDBn4w&v=3.exp&libraries=geometry,drawing,places"
            loadingElement={<div style={{ height: '100%' }} />}
            containerElement={<div />}
            mapElement={<div style={{ height: '100%' }} />}
          />
        </div>

        {/* <div className="container-contacts_wrap">
          <div className="contacts-row">
            <img
              src={PhoneIcon}
              width="18"
              height="18"
              alt=""
            ></img>
            <a className="phone" href="tel:+14033964494">+1403-396-4494</a>
          </div>
          <div className="contacts-row">
            <img
              src={MarkerIcon}
              width="18"
              height="18"
              alt=""
            ></img>
            <p>Rimbey, Alberta</p>
          </div>
        </div> */}
        <div className="copyright">
          <p>© Copyright 2021-2023, Horobets Bee Ranch. All rights reserved</p>
        </div>
    </div>
  );
};

export default ContactsMain

const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={locationProps.zoom}
    defaultCenter={locationProps.center}
    defaultOptions={{
      styles: mapStyle,
      streetViewControl: false,
      scaleControl: false,
      mapTypeControl: false,
      panControl: false,
      zoomControl: false,
      rotateControl: false,
      fullscreenControl: false
    }}
    disableDefaultUI
  >
    <Marker position={{ lat: 52.639349, lng: -114.244892  }} icon={icon}>
      <InfoWindow position={{ lat: locationProps.center.lat, lng: locationProps.center.lng }}>
        <div className='contact-map_card'>
          <h2>Contacts</h2>
          <div className='contact-map_card-row'>
            <p>
              <img src={phone} alt=""/>
              Phone
            </p>
            <a href="tel:+14033964494">
              <span>+1403-396-4494</span>
            </a>
          </div>
          <div className='contact-map_card-row'>
            <p>
              <img src={adressPointerBlue} alt=""/>
              Adress
            </p>
            <a href="https://goo.gl/maps/YYj81ouejxw6yT2G8" target="__blank">
              <span>Rimbey, Alberta</span>
            </a>
          </div>
          <div className='contact-map_card-row'>
            <p>
              <img src={mailIcon} alt=""/>
              Email
            </p>
            <a href="mailto:horobets.bee.ranch@gmail.com">
              <span>horobets.bee.ranch@gmail.com</span>
            </a>
          </div>
          <div className='contact-socials'>
            <a href="">
              <InstagramIcon />
            </a>
            <a href="https://www.facebook.com/HorobetsBeeRanch" target="__blank">
              <FaceBookIcon />
            </a>
            <a href="tel:+14033964494">
              <ViberIcon />
            </a>
            <a href="">
              <YoutubeIcon />
            </a>
          </div>
        </div>
      </InfoWindow>
    </Marker>
  </GoogleMap>
))

const mapStyle = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
]