import React, { useState } from 'react'

import Email from '../../img/email.png'
import Mail from '../../img/mail.png'
import Phone from '../../img/smartphone.png'
import Placeholder from '../../img/placeholder.png'

import InstagramIcon from '../../img/InstagramOrange.js'
import FaceBookIcon from '../../img/FacebookOrange.js'
import YoutubeIcon from '../../img/YouTubeOrange.js'
import ViberIcon from '../../img/ViberOrange.js'

import mapIcon from '../../img/honeycombMap.svg'
import { withScriptjs, withGoogleMap, GoogleMap, Marker, InfoWindow } from "react-google-maps"

import { database } from '../../index.js'
import moment from "moment"
import toastr from 'toastr'

const locationProps = {
  center: {lat: 52.660240, lng: -114.241815},
  zoom: 9
}
const icon = { url: mapIcon, scaledSize: { width: 35, height: 35 } };

const Contacts = (props) => {
  const [ name, setName ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ text, setText ] = useState('')

  const handleChange = (e, field) => {
    switch (field) {
      case 'name':
        setName(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'text':
        setText(e.target.value);
        break;
    }
  }

  const send = () => {
    const path = "Messages/" + moment().format('YYYY-MM-DD')
    const newOrderKey  = database.ref().child(path).push().key
    const updates = {}
    updates[path + "/" + newOrderKey] = {
      name: name,
      email: email,
      text: text
    }
    database.ref().update(updates).then(() => {
      setName('')
      setEmail('')
      setText('')
      toastr.success('Success! We will contact you soon 😄', '', {  
        "positionClass": "toast-top-center",
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
      })
    })
  }

  return (
    <div>
      <div className="container container-delivery pb60" id="delivery">
          <h3 className="title">
            CONTACT US
          </h3>
          <div className='row' style={{margin: '0 auto', justifyContent: 'space-between', maxWidth: 800}}>
            <div className='contact-block'>
              <div className='contact-block_row'>
                <img
                  src={Phone}
                  width="50"
                  height="50"
                  alt=""
                />
                <a href="tel:+14033964494">+1403-396-4494</a>
              </div>
              <div className='contact-block_row'>
                <p style={{marginRight: '1rem'}}>co-owner:</p>
                <a href="tel:+14033962926">+1403-396-2926</a>
              </div>
              <div className='contact-block_row'>
                <img
                  src={Email}
                  width="50"
                  height="50"
                  alt=""
                />
                <a href="mailto: horobets.bee.ranch@gmail.com">horobets.bee.ranch@gmail.com</a>
              </div>
              <form action="" className='contact-block_form'>
                <p>Send us a message:</p>
                <input
                  type="text"
                  placeholder='Name'
                  autoComplete='off'
                  value={name}
                  onChange={(e)=> handleChange(e, 'name')}
                />
                <input
                  type="email"
                  placeholder='Email'
                  autoComplete='off'
                  value={email}
                  onChange={(e)=> handleChange(e, 'email')}
                />
                <textarea
                  type="text"
                  rows={3}
                  rowsMax={8}
                  autoComplete='off'
                  placeholder='What can we help you with?'
                  value={text}
                  onChange={(e)=> handleChange(e, 'text')}
                />
                <button className='common-button_orange' onClick={(e)=> {
                  e.preventDefault()
                  send()
                }}>
                  Submit
                </button>
              </form>
            </div>

            <div className='contact-block'>
              <div className='contact-block_row'>
                <img
                  src={Placeholder}
                  width="50"
                  height="50"
                  alt=""
                />
                <p>5013 50 Ave, Rimbey, <br/>AB T0C 2J0</p>
                
                {/* B0x 1847, Rimbey, <br/>Alberta, T0C2J0 */}
              </div>
              <div className='contact-map_wrap' style={{height:'18rem',marginBottom: 10}}>
                <MyMapComponent
                  isMarkerShown
                  googleMapURL="https://maps.googleapis.com/maps/api/js?key=AIzaSyDWEP2VV5iCm287wPW6jZz4gBX_6TDBn4w&v=3.exp&libraries=geometry,drawing,places"
                  loadingElement={<div style={{ height: '100%' }} />}
                  containerElement={<div />}
                  mapElement={<div style={{ height: '100%' }} />}
                />
              </div>
              
              <div>
                <p style={{
                  textAlign: 'left',
                  color: '#000',
                  fontWeight: 600,
                  fontSize: '1.1rem',
                  fontFamily: 'sans-serif',
                  }}
                >
                  FOLLOW US:
                </p>
                <div className='contact-socials'>
                  <a href="">
                    <InstagramIcon />
                  </a>
                  <a href="https://www.facebook.com/HorobetsBeeRanch" target="__blank">
                    <FaceBookIcon />
                  </a>
                  <a href="tel:+14033964494">
                    <ViberIcon />
                  </a>
                  <a href="">
                    <YoutubeIcon />
                  </a>
                </div>
              </div>
            </div>
          </div>
         
            
          
          {/* <div className="row">
            What about delivery?
          </div> */}
      </div>
    </div>
  )
}

export default Contacts



const MyMapComponent = withScriptjs(withGoogleMap((props) =>
  <GoogleMap
    defaultZoom={locationProps.zoom}
    defaultCenter={locationProps.center}
    defaultOptions={{
      styles: mapStyle,
      streetViewControl: false,
      scaleControl: false,
      mapTypeControl: false,
      panControl: false,
      zoomControl: false,
      rotateControl: false,
      fullscreenControl: false
    }}
    disableDefaultUI
  >
    <Marker position={{ lat: 52.639349, lng: -114.244892  }} icon={icon}>
    </Marker>
  </GoogleMap>
))

const mapStyle = [
  {
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "elementType": "labels.icon",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#212121"
      }
    ]
  },
  {
    "featureType": "administrative",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "administrative.country",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#9e9e9e"
      }
    ]
  },
  {
    "featureType": "administrative.locality",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#bdbdbd"
      }
    ]
  },
  {
    "featureType": "poi",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#181818"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "poi.park",
    "elementType": "labels.text.stroke",
    "stylers": [
      {
        "color": "#1b1b1b"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2c2c2c"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#8a8a8a"
      }
    ]
  },
  {
    "featureType": "road.arterial",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#373737"
      }
    ]
  },
  {
    "featureType": "road.highway",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#3c3c3c"
      }
    ]
  },
  {
    "featureType": "road.highway.controlled_access",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#4e4e4e"
      }
    ]
  },
  {
    "featureType": "road.local",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#616161"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#757575"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry",
    "stylers": [
      {
        "color": "#000000"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#3d3d3d"
      }
    ]
  }
]