const config = {
  apiKey: "AIzaSyCTT5LNsMDm_Cq7e2RmSNHknbWgg76wdUc",
  authDomain: "hbranch-6780b.firebaseapp.com",
  databaseURL: "https://hbranch-6780b-default-rtdb.firebaseio.com",
  projectId: "hbranch-6780b",
  storageBucket: "hbranch-6780b.appspot.com",
  messagingSenderId: "126165749378",
  appId: "1:126165749378:web:981555f24f5797a7112e9b",
  measurementId: "G-ZT21EH2C6K"
}

export default config