import React from 'react'

const Icon = () =>

<svg xmlns="http://www.w3.org/2000/svg"
 viewBox="0 0 512.00096 512.00096" 
 width="512px" height="512px" id='icon_2'>
 <g><path d="m373.40625 0h-234.8125c-76.421875 0-138.59375 62.171875-138.59375 138.59375v234.816406c0 
 76.417969 62.171875 138.589844 138.59375 138.589844h234.816406c76.417969 0 138.589844-62.171875
  138.589844-138.589844v-234.816406c0-76.421875-62.171875-138.59375-138.59375-138.59375zm-117.40625 
  395.996094c-77.195312 0-139.996094-62.800782-139.996094-139.996094s62.800782-139.996094
   139.996094-139.996094 139.996094 62.800782 139.996094 139.996094-62.800782 
   139.996094-139.996094 139.996094zm143.34375-246.976563c-22.8125
    0-41.367188-18.554687-41.367188-41.367187s18.554688-41.371094 
    41.367188-41.371094 41.371094 18.558594 41.371094 41.371094-18.558594 
    41.367187-41.371094 41.367187zm0 0" data-original="#000000" 
     data-old_color="#f96514" fill="#f96514" className='active-social'/>
    <path d="m256 146.019531c-60.640625 0-109.980469 49.335938-109.980469 109.980469 0 60.640625 49.339844
     109.980469 109.980469 109.980469 60.644531 0 109.980469-49.339844 109.980469-109.980469 
     0-60.644531-49.335938-109.980469-109.980469-109.980469zm0 0" data-original="#000000"
       data-old_color="#f96514" fill="#f96514" className='active-social'/>
      <path d="m399.34375 96.300781c-6.257812 0-11.351562 5.09375-11.351562 11.351563 0 6.257812 5.09375 11.351562 
      11.351562 11.351562 6.261719 0 11.355469-5.089844 11.355469-11.351562 0-6.261719-5.09375-11.351563-11.355469-11.351563zm0 0"
       data-original="#000000" className='active-social' data-old_color="#f96514" fill="#f96514"/></g> </svg>


export default Icon

