import React, { useState } from 'react'
import Card from 'react-bootstrap/Card'

import Swiper from 'react-id-swiper';
import SwiperCore, { Lazy, Navigation, Autoplay, Pagination } from 'swiper'
import OrderModal from '../../components/OrderModal'

import PriceImg1 from '../../img/price-img1.jpg'
import PriceImg2 from '../../img/price-img2.jpg'
import PriceImg3 from '../../img/price-img3.jpg'

import HoneyPot from '../../img/honey-pot.svg'
import HoneyItem from '../../img/honey-item.png'
import Nuc from '../../img/nuc.jpg'
import Queens from '../../img/queens.jpg'
import Pollen from '../../img/price-pollen.jpg'
import Comb from '../../img/comb.jpg'

import SoldOut from '../../img/soldOut.png'

import 'swiper/swiper-bundle.css';

SwiperCore.use([Lazy, Navigation, Autoplay, Pagination ])


const Products = (props) => {

  const [ showModal, setShowModal] = useState(false)
  const [ product, setProduct ] = useState('')
  const [ volume, setVolume ] = useState('')

  const order = (showModal, orderedProduct, orderedVolume) => {
    setShowModal(showModal)
    setProduct(orderedProduct)
    setVolume(orderedVolume)
  }

  const params = {
    grabCursor: true,
    spaceBetween: 10,
    slidesPerView: 1,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    breakpoints: {
      319: {
        slidesPerView: 1.25,
        spaceBetween: 20,
        centeredSlides: true,
      },
      768: {
        slidesPerView: 2.5,
        spaceBetween: 50,
        centeredSlides: true,

      },
      968: {
        slidesPerColumn: 3,
        spaceBetween: 30,
        slidesPerView: 3,
        slidesPerColumnFill: 'row',
      },
    }
  }

  return (
    <div>
      {showModal &&
        <OrderModal showModal={showModal} setShowModal={setShowModal} orderedProduct={product} orderedVolume={volume}/>
      }
      <div className="container container-price" id='price'>
        <h3 className="title">
          Honey
        </h3>
        <div className="row pb30 slider-products" style={{margin: 'auto'}}>

          <Swiper {...params}>
            <div>
              <Card style={{borderRadius: '20px', border:'0px',textAlign:'center'}}>
                <Card.Img
                  variant="top"
                  src={PriceImg1}
                  layout="fill"
                  className='cover-img'
                  alt=""
                  style={{borderRadius: '20px 20px 0px 0px'}}
                />
                <Card.Body>
                  <Card.Title>
                    <div className="price-card_price">
                      <h4>$15<span> CAD</span></h4>
                      <div>
                        <img
                          src={HoneyPot}
                          width="18"
                          height="20"
                          alt=''
                        />
                        <p className="text">1 kg</p>
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Text style={{paddingBottom: '1rem'}}>
                    <p className="price-card_text">
                      Pail of raw honey
                    </p>
                  </Card.Text>
                  <div className="price-card_button">
                    <button className="common-button_orange" onClick={() => order(!showModal, 'Honey', '1')}>
                      ORDER
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </div>

            <div>
              <Card style={{borderRadius: '20px', border:'0px',textAlign:'center'}}>
                <Card.Img
                  variant="top"
                  src={PriceImg2}
                  layout="fill"
                  className='cover-img'
                  alt=""
                  style={{borderRadius: '20px 20px 0px 0px'}}
                />
                <Card.Body>
                  <Card.Title>
                    <div className="price-card_price">
                      <h4>$35<span> CAD</span></h4>
                      <div>
                        <img
                          src={HoneyPot}
                          width="18"
                          height="20"
                          alt=''
                        />
                        <p className="text">3 kg</p>
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Text style={{paddingBottom: '1rem'}}>
                    <p className="price-card_text">
                      Pail of raw honey
                    </p>
                  </Card.Text>
                  <div className="price-card_button">
                    <button className="common-button_orange" onClick={() => order(!showModal, 'Honey', '3')}>
                      ORDER
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </div>

            <div>
              <Card style={{borderRadius: '20px', border:'0px',textAlign:'center'}}>
                <Card.Img
                  variant="top"
                  src={PriceImg3}
                  layout="fill"
                  className='cover-img'
                  alt=""
                  style={{borderRadius: '20px 20px 0px 0px'}}
                />
                <Card.Body>
                  <Card.Title>
                    <div className="price-card_price">
                      <h4>$75<span> CAD</span></h4>
                      <div>
                        <img
                          src={HoneyPot}
                          width="18"
                          height="20"
                          alt=''
                        />
                        <p className="text">7 kg</p>
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Text style={{paddingBottom: '1rem'}}>
                    <p className="price-card_text">
                      Pail of raw honey
                    </p>
                  </Card.Text>
                  <div className="price-card_button">
                    <button className="common-button_orange" onClick={() => order(!showModal, 'Honey', '7')}>
                      ORDER
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </div>

            <div>
              <Card style={{borderRadius: '20px', border:'0px',textAlign:'center'}}>
                <Card.Img
                  variant="top"
                  src={PriceImg3}
                  layout="fill"
                  className='cover-img'
                  alt=""
                  style={{borderRadius: '20px 20px 0px 0px'}}
                />
                <Card.Body>
                  <Card.Title>
                    <div className="price-card_price">
                      <h4>$150<span> CAD</span></h4>
                      <div>
                        <img
                          src={HoneyPot}
                          width="18"
                          height="20"
                          alt=''
                        />
                        <p className="text">15 kg</p>
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Text style={{paddingBottom: '1rem'}}>
                    <p className="price-card_text">
                      Pail of raw honey
                    </p>
                  </Card.Text>
                  <div className="price-card_button">
                    <button className="common-button_orange" onClick={() => order(!showModal, 'Honey', '7')}>
                      ORDER
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </div>

            <div>
              <Card style={{borderRadius: '20px', border:'0px',textAlign:'center'}}>
                <Card.Img
                  variant="top"
                  src={Queens}
                  layout="fill"
                  className='cover-img'
                  alt=""
                  style={{borderRadius: '20px 20px 0px 0px'}}
                />
                {/* <img src={SoldOut} alt="" className='sold-out-img'/> */}
                <Card.Body>
                  <Card.Title>
                    <div className="price-card_price">
                      <h4><span>From</span> $40<span> CAD</span></h4>
                      <div>
                        <p className="text">1</p>
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Text style={{paddingBottom: '1rem'}}>
                    <p className="price-card_text">
                      Queens 2023
                    </p>
                  </Card.Text>
                  <div className="price-card_button">
                    <button className="common-button_orange" onClick={() => order(!showModal, 'Queens', '')}>
                      ORDER
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </div>

            <div>
              <Card style={{borderRadius: '20px', border:'0px',textAlign:'center'}}>
                <Card.Img
                  variant="top"
                  src={Nuc}
                  layout="fill"
                  className='cover-img'
                  alt=""
                  style={{borderRadius: '20px 20px 0px 0px'}}
                />
                {/* <img src={SoldOut} alt="" className='sold-out-img'/> */}
                <Card.Body>
                  <Card.Title>
                    <div className="price-card_price">
                      <h4><span>From</span> $250<span> CAD</span></h4>
                      <div>
                        <p className="text">4 Frame</p>
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Text style={{paddingBottom: '1rem'}}>
                    <p className="price-card_text">
                      4 Frame nucleus colony - 2023
                    </p>
                  </Card.Text>
                  <div className="price-card_button">
                    <button className="common-button_orange" onClick={() => order(!showModal, 'Colony', '')}>
                      ORDER
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </div>

            <div>
              <Card style={{borderRadius: '20px', border:'0px',textAlign:'center'}}>
                <Card.Img
                  variant="top"
                  src={Pollen}
                  layout="fill"
                  className='cover-img'
                  alt=""
                  style={{borderRadius: '20px 20px 0px 0px'}}
                />
                {/* <img src={SoldOut} alt="" className='sold-out-img'/> */}
                <Card.Body>
                  <Card.Title>
                    <div className="price-card_price">
                      <h4>$45<span> CAD</span></h4>
                      <div>
                        <p className="text">500 g</p>
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Text style={{paddingBottom: '1rem'}}>
                    <p className="price-card_text">
                      Fresh bee pollen
                    </p>
                  </Card.Text>
                  <div className="price-card_button">
                    <button className="common-button_orange" onClick={() => order(!showModal, 'Pollen', '')}>
                      ORDER
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </div>

            <div>
              <Card style={{borderRadius: '20px', border:'0px',textAlign:'center'}}>
                <Card.Img
                  variant="top"
                  src={Comb}
                  layout="fill"
                  className='cover-img'
                  alt=""
                  style={{borderRadius: '20px 20px 0px 0px'}}
                />
                <img src={SoldOut} alt="" className='sold-out-img'/>
                <Card.Body>
                  <Card.Title>
                    <div className="price-card_price">
                      <h4>$18<span> CAD</span></h4>
                      <div>
                        <p className="text">4” x 4”</p>
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Text style={{paddingBottom: '1rem'}}>
                    <p className="price-card_text">
                      Pure raw comb honey
                    </p>
                  </Card.Text>
                  <div className="price-card_button">
                    <button className="common-button_orange" onClick={() => order(!showModal, 'Comb', '')} disabled={true}>
                      ORDER
                    </button>
                  </div>
                </Card.Body>
              </Card>
            </div>

          </Swiper>

        </div>
      </div>

      <div className="container container-product pb30" id='honey' style={{backgroundColor: '#1E1420'}}>
        <h3 className="title" style={{color: '#0B0A0A'}}>
          About honey
        </h3>
        <div className="row" style={{display: 'inherit', margin: 'auto'}}>
          <div className="product-row">
            <div>
              <img
                src={HoneyItem}
                width="40"
                height="42"
                alt=""
                sizes="(min-width: 968px) 55px, 40px"
              />
            </div>
            <p>Only <span className="bold-text">100% natural honey</span></p>
          </div>
          <div className="product-row">
            <div>
              <img
                src={HoneyItem}
                width="40"
                height="42"
                alt=""
                sizes="(min-width: 968px) 55px, 40px"
              />
            </div>
            <p>Raw honey is<span className="bold-text"> unheated, unpasteurized</span></p>
          </div>
          <div className="product-row">
            <div>
              <img
                src={HoneyItem}
                width="40"
                height="42"
                alt=""
                sizes="(min-width: 968px) 55px, 40px"
              />
            </div>
            <p>Our honey is<span className="bold-text"> directly from the beehive</span></p>
          </div>
          <div className="product-row">
            <div>
              <img
                src={HoneyItem}
                width="40"
                height="42"
                alt=""
                sizes="(min-width: 968px) 55px, 40px"
              />
            </div>
            <p>
              <span className="bold-text">Delicious way to add a </span> healthy sweet touch to your meal
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Products