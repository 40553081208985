import React from 'react'

import { Carousel } from 'rsuite'
import SwiperCore, { Lazy, Navigation, Autoplay, Pagination } from 'swiper'
import Swiper from 'react-id-swiper';
import 'swiper/swiper-bundle.css';

import PollinationBg1 from '../../img/pol_bg_1.jpg'
import PollinationBg2 from '../../img/pol_bg_2.JPG'
import PollinationBg3 from '../../img/pol_bg_3.JPG'
import PollinationBg4 from '../../img/pol_bg_4.JPG'
import PollinationAbout from '../../img/about_pollination.jpg'
import PollinationAbout2 from '../../img/about_pollination2.jpg'

SwiperCore.use([Lazy, Navigation, Autoplay, Pagination ])


const Pollination = (props) => {

  const paramsAbout = {
    grabCursor: true,
    loop: true,
    spaceBetween: 10,
    // centeredSlides: true,
    slidesPerView: 1,

    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev'
    },
    // pagination: {
    //   el: '.swiper-pagination',
    //   clickable: true,
    // },
    autoplay: {
      delay: 4000,
      disableOnInteraction: false
    },
  }

  return (
    <div className="container container-header" id='header'>
      <div className="row pb60">
        <div className="header">
          <h4>
            <span className='orange-text'>
              Pollination <br/>for your farm
            </span>
            <br/>
            with our bees
          </h4>
          <br/><br/>
            <button className="common-button_orange">
              ORDER NOW
            </button>
        </div>
        <Carousel
          autoplay
          className="header-carousel"
        >
          <img
            src={PollinationBg1}
            alt=""
          />
          <img
            src={PollinationBg2}
            alt=""
          />
          <img
            src={PollinationBg3}
            alt=""
          />
          <img
            src={PollinationBg4}
            alt=""
          />
        </Carousel>
      </div>

      <div className="container container-about" id='about'>
        <div>
          <h3 className="title">
            Why are pollinators important?
          </h3>
          <p className="text">
            Some of the many foods that rely on pollinators <br/>
            Somewhere between 75% and 95% of all flowering plants on the earth need help with pollination – they need pollinators. 
            Pollinators provide pollination services to over 180,000 different plant species and more than 1200 crops. 
            That means that 1 out of every three bites of food you eat is there because of pollinators.

          </p>
        </div>
        <div className="row about-slider">
          <Swiper
            {...paramsAbout}
          >
            <img
              className="rounded-img"
              src={PollinationAbout}
              alt=""
            />
            <img
              className="rounded-img"
              src={PollinationAbout2}
              alt=""
            />
          </Swiper>
        </div>
        
      </div>
    </div>
  )
}

export default Pollination