import React, { useState } from 'react'

import Extractor from '../../img/extractor.jpg'
import PriceImg2 from '../../img/price-img2.jpg'
import PriceImg3 from '../../img/price-img3.jpg'

import ArrowRight from '../../img/arrow-right.svg'
import ArrowLeft from '../../img/arrow-left.svg'
import Clock from '../../img/clock.png'
import HoneyItem from '../../img/honey-item.png'

import Card from 'react-bootstrap/Card'
import CardDeck from 'react-bootstrap/CardDeck'
import Button from 'react-bootstrap/Button'

import SwiperCore, { Lazy, Navigation, Autoplay, Pagination } from 'swiper'
import Swiper from 'react-id-swiper';
import 'swiper/swiper-bundle.css';
SwiperCore.use([Lazy, Navigation, Autoplay, Pagination ])

const Rental = (props) => {

  const params = {
    grabCursor: true,
    // navigation: {
    //   nextEl: '.swiper-button-next',
    //   prevEl: '.swiper-button-prev'
    // },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    // autoplay: {
    //   delay: 4000,
    //   disableOnInteraction: false
    // },
    breakpoints: {
      319: {
        slidesPerView: 1.25,
        spaceBetween: 20,
        centeredSlides: true,
      },
      768: {
        slidesPerView: 2.5,
        spaceBetween: 50,
        centeredSlides: true,

      },
      968: {
        slidesPerView: 3,
        spaceBetween: 30,
      },
    }
  }

  return (
    <div>
      <div className="container container-price" id='price' style={{backgroundColor:'#fff'}}>
        <h3 className="title" style={{color:'#0B0A0A'}}>
          Coming soon!
        </h3>
        <h5 style={{color:'#0B0A0A', fontSize: '20px', textAlign: 'center', fontWeight: 'bold', fontFamily: 'Avenir Next Cyr'}}>
          We are currently working on this feature and will launch soon!
        </h5>
        <div className="row pb30 slider-products">
{/*            <Swiper
              {...params}
            >
              <div>
                <Card style={{  borderRadius: '20px', border:'0px',backgroundColor:'#fedbc9'}}>
                  <Card.Img
                    variant="top"
                    src={Extractor}
                    layout="fill"
                    className='cover-img'
                    alt=""
                    style={{borderRadius: '20px 20px 0px 0px'}}
                  />
                  <Card.Body>
                    <Card.Title>
                      <div className="price-card_price">
                        <h4>100<span> CAD</span></h4>
                        <img
                          src={Clock}
                          width="20"
                          height="20"
                          alt=""
                        ></img>
                        <p className="text">per day</p>
                      </div>
                    </Card.Title>
                    <Card.Text style={{paddingBottom: '1rem'}}>
                      <p className="price-card_text">
                        <span className="bold-text" style={{color: 'black'}}>56 Frame Radial Extractor</span>
                        <br/>The 56 frame radial honey extractor with legs is excellent for a few colonies to 
                        several hundred. Holds any size frame and has a powerful speed to extract honey 
                        quickly and efficiently.
                      </p>
                    </Card.Text>
                    <div className="price-card_button" style={{textAlign: 'center'}}>
                      <button className="common-button_orange">
                        ORDER
                      </button>
                    </div>
                  </Card.Body>
                </Card>
                </div>

                <div>

                
                <Card style={{  borderRadius: '20px', border:'0px',backgroundColor:'#fedbc9'}}>
                  <Card.Img
                    variant="top"
                    src={PriceImg2}
                    layout="fill"
                    className='cover-img'
                    alt=""
                    style={{borderRadius: '20px 20px 0px 0px'}}
                  />
                  <Card.Body>
                    <Card.Title>
                      <div className="price-card_price">
                        <h4>120<span> грн</span></h4>
                        <img
                          src={Clock}
                          width="20"
                          height="20"
                          alt=""
                        ></img>
                        <p className="text">1 л</p>
                      </div>
                    </Card.Title>
                    <Card.Text style={{paddingBottom: '1rem'}}>
                      <p className="price-card_text">
                        Мёд разнотравье, собран
                        27 июля 2020 года
                      </p>
                    </Card.Text>
                    <div className="price-card_button" style={{textAlign: 'center'}}>
                      <button className="common-button_orange">
                        ЗАКАЗАТЬ
                      </button>
                    </div>
                  </Card.Body>
                </Card>
                </div>

                <div>

                <Card style={{  borderRadius: '20px', border:'0px',backgroundColor:'#fedbc9'}}>
                  <Card.Img
                    variant="top"
                    src={PriceImg3}
                    layout="fill"
                    className='cover-img'
                    alt=""
                    style={{borderRadius: '20px 20px 0px 0px'}}
                  />
                  <Card.Body>
                    <Card.Title>
                      <div className="price-card_price">
                        <h4>350<span> грн</span></h4>
                        <img
                          src={Clock}
                          width="20"
                          height="20"
                          alt=""
                        ></img>
                        <p className="text">3 л</p>
                      </div>
                    </Card.Title>
                    <Card.Text style={{paddingBottom: '1rem'}}>
                      <p className="price-card_text">
                        Мёд разнотравье, собран
                        27 июля 2020 года
                      </p>
                    </Card.Text>
                    <div className="price-card_button" style={{textAlign: 'center'}}>
                      <button className="common-button_orange">
                        ЗАКАЗАТЬ
                      </button>
                    </div>
                  </Card.Body>
                </Card>
                </div>

          </Swiper>*/}
        </div>
      </div>
    </div>
  )
}

export default Rental