import React from 'react';
import ReactDOM from 'react-dom';
import 'bootstrap/dist/css/bootstrap.min.css'
import 'rsuite/dist/styles/rsuite-default.css'
import 'toastr/build/toastr.min.css'

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import firebase from 'firebase/app'
import 'firebase/database'
import 'firebase/storage'
import config from './firebaseConfig.js'

firebase.initializeApp(config)
const database = firebase.database()
const databaseConst = firebase.database

export { database, databaseConst }

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
