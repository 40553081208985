import React, { useState }  from 'react'

import moment from "moment"
import toastr from 'toastr'

import { Modal } from 'react-bootstrap'
import { InputGroup, InputNumber } from 'rsuite'

import { database } from '../index.js'


const OrderModal = ({showModal, setShowModal, orderedProduct, orderedVolume}) => {

  const [ name, setName ] = useState('')
  const [ phone, setPhone ] = useState('')
  const [ email, setEmail ] = useState('')
  const [ volume, setVolume] = useState(orderedVolume || '')
  const [ product, setProduct] = useState(orderedProduct || '')
  const [ qty, setQty] = useState(1)

  const handleChange = (e, field) => {
    switch (field) {
      case 'name':
        setName(e.target.value);
        break;
      case 'phone':
        setPhone(e.target.value);
        break;
      case 'email':
        setEmail(e.target.value);
        break;
      case 'product':
        setProduct(e.target.value);
        break;
      case 'volume':
        setVolume(e.target.value);
        break;
      case 'qty':
        setQty(e);
        break;
    }
  }

  const inputRef = React.createRef();
  const handleMinus = () => {
    inputRef.current.handleMinus();
  };
  const handlePlus = () => {
    inputRef.current.handlePlus();
  };

  const sendOrder = () => {
    const path = "Orders/" + moment().format('YYYY-MM-DD')
    const newOrderKey  = database.ref().child(path).push().key
    const updates = {}
    updates[path + "/" + newOrderKey] = {
      name: name,
      phone: phone,
      email: email,
      volume: volume,
      product: product,
      qty: qty
    }
    database.ref().update(updates).then(() => {
      setShowModal(!showModal)
      toastr.success('Success! We will contact you soon 😄', '', {  
        "positionClass": "toast-top-center",
        "showDuration": "300",
        "hideDuration": "1000",
        "timeOut": "5000",
      })
    })
  }

  return(
    <Modal show={showModal} onHide={setShowModal} className='order-modal'>
      <Modal.Header closeButton>
        Order info
      </Modal.Header>
      <Modal.Body>

        <form action={(e)=> e.preventDefault()}>
          <input
            type="text"
            placeholder='Name'
            value={name}
            onChange={(e)=> handleChange(e, 'name')}
          />
          <input
            type="number"
            placeholder='Phone'
            value={phone}
            onChange={(e)=> handleChange(e, 'phone')}
          />
          <input
            type="text"
            placeholder='Email'
            value={email} 
            onChange={(e)=> handleChange(e, 'email')}
          />
          <select
            name=""
            id=""
            value={product}
            onChange={(e)=> handleChange(e, 'product')}
          >
            <option value="Honey">Honey</option>
            <option value="Pollen">Bee pollen</option>
            <option value="Queens">Queens</option>
            <option value="Colony">Nucleus colony</option>
            <option disabled value="Comb">Comb honey</option>
          </select>

          {product == ('Honey' || '') &&
            <select
              name=""
              id=""
              value={volume}
              onChange={(e)=>
              handleChange(e, 'volume')}
            >
              <option value="Volume">Volume</option>
              <option value="1">1 kg</option>
              <option value="3">3 kg</option>
              <option value="7">7 kg</option>
            </select>
          }

          <div className='label-and-input'>
            <p>Qty</p>
            <InputGroup className='modal-input-block'>
              <InputGroup.Button onClick={handleMinus}>
                -
              </InputGroup.Button>
              <InputNumber
                ref={inputRef}
                max={99}
                min={1}
                value={qty}
                onChange={(e)=> handleChange(e, 'qty')}
              />
              <InputGroup.Button onClick={handlePlus}>
                +
              </InputGroup.Button>
            </InputGroup>
          </div>

          <button
            className="common-button_orange"
            onClick={(e)=> {
              e.preventDefault()
              sendOrder()
            }}
          >
            Confirm
          </button>
        </form>

      </Modal.Body>
    </Modal>
  )
}

export default OrderModal
