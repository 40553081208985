import './App.css';

import React from 'react';
import {
	BrowserRouter as Router,
  Switch,
	Route
} from "react-router-dom"

import NavBar from './components/NavBar'
import ContactsMain from './components/ContactsMain'
import About from './routes/About/'
import Contacts from './routes/Contacts/'
import Products from './routes/Products/'
import Pollination from './routes/Pollination/'
import Rental from './routes/Rental/'
import ScrollToTop from './ScrollToTop'


function App() {
  return (
  	<Router>
	    <div>
	      <NavBar/>
        <ScrollToTop />
	    	<Switch>
        	<Route exact path="/" component={About} />
			    <Route path="/contacts" component={Contacts} />
			    <Route path="/products" component={Products} />
			    <Route path="/pollination" component={Pollination} />
			    <Route path="/rental" component={Rental} />
        </Switch>
        <ContactsMain/>
	    </div>
    </Router>
  );
}

export default App;
